import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  // subjectSelected: stores info if User or Approver etc. is selected
  static values = { start: String, end: String, activeTab: String, hiddenStates: Array, subjectSelected: { type: Boolean, default: false } }
  static targets = [ 'tab', 'activeTabField', 'filtersTabs', 'states', 'statesPrivateTab' ]

  connect() {
    const that = this

    this.tabTargets.forEach((tab) => {
      tab.addEventListener('shown.bs.tab', function (event) {
        const activeTab = event.target // newly activated tab

        const activeTabPaneId = activeTab.getAttribute('aria-controls')

        that.activeTabValue = activeTabPaneId

        const activeTabPane = that.element.querySelector(`.tab-pane#${activeTabPaneId}`)
        const form = activeTabPane.querySelector('form')
        const select = form.querySelector('select')

        that.subjectSelectedValue = select.selectedIndex > 0

        if(that.subjectSelectedValue) {
          form.requestSubmit()
        }
      })
    })

    this.filtersTabsTarget.addEventListener('show.bs.collapse', () => {
      Cookies.set('eleave_filters_expanded', true)
    })

    this.filtersTabsTarget.addEventListener('hide.bs.collapse', () => {
      Cookies.remove('eleave_filters_expanded')
    })
  }

  activeTabValueChanged() {
    const activeTabIsPrivate = ['user', 'approvee', 'approver'].includes(this.activeTabValue)

    this.statesPrivateTabTargets.forEach((ss) => {
      if(activeTabIsPrivate) {
        ss.classList.remove('d-none')
      } else {
        ss.classList.add('d-none')
      }
    })

    // catched by eleave--warnings
    this.dispatch('tab-changed', { detail: { value: this.activeTabValue, subjectSelected: this.subjectSelectedValue } })
  }

  subjectChanged(event) {
    const form = event.target.closest('form')
    const select = form.querySelector('select')

    this.subjectSelectedValue = select.selectedIndex > 0

    if(!this.subjectSelectedValue) {
      return // do not submit form if no subject selected
    }

    if(this.startValue && this.endValue) {
      let url = new URL(form.getAttribute('action'), window.location.origin)
      url.searchParams.set('start', this.startValue)
      url.searchParams.set('end', this.endValue)

      if(this.hiddenStatesValue.length > 0) {
        url.searchParams.delete('hidden_states[]')

        this.hiddenStatesValue.forEach((v, _i) => {
          url.searchParams.append(`hidden_states[]`, v)
        })
      }

      form.setAttribute('action', url.toString())
    }

    form.requestSubmit()
  }

  stateChanged(event) {
    const state = event.params.state
    const checked = event.target.checked

    if(checked) {
      this.hiddenStatesValue = this.hiddenStatesValue.filter(s => s !== state)
    } else {
      this.hiddenStatesValue = this.hiddenStatesValue.concat(state)
    }
  }

  // send event to calendar to show/hide events for specified states
  hiddenStatesValueChanged() {
    this.dispatch('hidden-states-changed', { detail: { value: this.hiddenStatesValue } })
  }

  subjectSelectedValueChanged() {
    if(this.subjectSelectedValue) {
      this.statesTarget.classList.remove('d-none')
    } else {
      this.statesTarget.classList.add('d-none')
    }
    // send event to calendar to hide containers which require subject selection
    this.dispatch('subject-selected-changed', { detail: { value: this.subjectSelectedValue } })
  }

  startChangedCallback(event) {
    this.startValue = event.detail.value
  }

  endChangedCallback(event) {
    this.endValue = event.detail.value
  }
}
