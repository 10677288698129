// used to render overlappings shifts/shift swaps/leave periods in leave period modal
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { calendarBaseUrl: String,
                    userUuid: String,
                    authToken: String,

                    fromDate: String,
                    untilDate: String,
                    startTime: String,
                    endTime: String,

                    preload: { type: Boolean, default: true },
                    loading: { type: Boolean, default: false },
                    approve: { type: Boolean, default: false } } // indicates that approver opened the modal

  static targets = [ 'frame', 'loader' ]

  connect() {
    addEventListener('turbo:before-fetch-request', async (event) => {
      if(event.target.getAttribute('id') == this.frameId()) {
        this.loadingValue = true
      }
    })

    this.frameTarget.addEventListener("turbo:frame-render", async (event) => {
      this.loadingValue = false
    })

    if(this.preloadValue) {
      this.frameTarget.src = this.prepareUrl()
    }
  }

  checkRoster(_event) {
    this.frameTarget.src = this.prepareUrl()
  }

  rangeChangedCallback(event) {
    // hide turbo frame with mini calendar if range changed
    this.frameTarget.classList.add('d-none')

    // assign values user for url building
    for(const [key, value] of Object.entries(event.detail)) {
      this[key + 'Value'] = value
    }
  }

  prepareUrl() {
    const starts_at_str = `${this.fromDateValue} ${this.startTimeValue}`
    const starts_at_iso = new Date(starts_at_str).toISOString()

    const ends_at_str = `${this.untilDateValue} ${this.endTimeValue}`
    const ends_at_iso = new Date(ends_at_str).toISOString()

    let url = new URL(this.calendarBaseUrlValue)
    url.searchParams.set('turbo_frame_id', this.frameId())
    url.searchParams.set('starts_at', starts_at_iso)
    url.searchParams.set('ends_at', ends_at_iso)
    url.searchParams.set('uuid', this.userUuidValue)

    url.searchParams.set('auth_token', this.authTokenValue)

    return url.toString()
  }

  loadingValueChanged() {
    if(this.loadingValue) {
      this.loaderTarget.classList.remove('d-none')
      this.frameTarget.classList.add('d-none')

      this.loaderTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      this.loaderTarget.classList.add('d-none')
      this.frameTarget.classList.remove('d-none')
    }
  }

  frameId() {
    return this.frameTarget.getAttribute('id')
  }
}
