import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { initialProportionPercent: Number,
                    dayWarningsLimit: Number,
                    proportionPercent: Number, // days with more that 25% of
                    proportionSize: Number, // 32 users on leave
                    activeTab: String }

  static targets = [ 'widget', 'input', 'proportionPercent', 'proportionSize', 'subjectSelect', 'calendar' ]

  connect() {
    this.proportionPercentValue = this.initialProportionPercentValue
  }

  // from calendar, triggered after events loaded
  updateWarningsCallback(event) {
    if(event.detail.active_tab) {
      this.activeTabValue = event.detail.active_tab

      const select = this.subjectSelectTargets.find((el) => el.id == `${this.activeTabValue}_id`)

      const option = select.options[select.selectedIndex]
      this.proportionSizeValue = option.dataset.size || 0
    }

    if(this.activeTabValue) {
      this.calculateAndUpdateProportionWarning()
    }
  }

  // from filters, if no subject hide warnings widget
  tabChangedCallback(event) {
    this.dayWarningsLimitValue = null // to make sure it changes on tab change
    this.activeTabValue = event.detail.value

    if(event.detail.subjectSelected) {
      // get selected option and read data-size attribute
      const select = this.subjectSelectTargets.find((el) => el.id == `${this.activeTabValue}_id`)
      const size = select.options[select.selectedIndex].dataset.size || 0

      this.proportionSizeValue = size
    }

    if(!this.activeTabValue || !event.detail.subjectSelected) {
      this.widgetTarget.classList.add('d-none')
    }
  }

  dayWarningsLimitValueChanged() {
    if (!this.activeTabHasWarnings()) {
      return
    }

    this.updateDayWarnings()
  }

  updateDayWarnings() {
    if (!this.activeTabHasWarnings()) {
      return
    }

    this.calendarTarget.querySelectorAll('.fc-day[data-overlapping-events-count]').forEach((el) => {
      const count = el.getAttribute('data-overlapping-events-count')

      if(count && count >= this.dayWarningsLimitValue) {
        el.classList.add('fc-warning')
      } else {
        el.classList.remove('fc-warning')
      }
    })
  }

  proportionSizeValueChanged() {
    this.proportionSizeTarget.innerText = `${this.proportionSizeValue} ${this.proportionSizeValue == 0 || this.proportionSizeValue > 1 ? 'users' : 'user'}`
  }

  proportionPercentChanged(event) {
    this.proportionPercentValue = event.srcElement.value
  }

  proportionPercentValueChanged() {
    this.calculateAndUpdateProportionWarning()

    this.updateSliderTrackBackground()
  }

  calculateAndUpdateProportionWarning() {
    if(!this.activeTabHasWarnings()) {
      return
    }

    this.proportionPercentTarget.innerText = `${this.proportionPercentValue}%`

    const limit = parseFloat(( this.proportionSizeValue * this.proportionPercentValue / 100).toFixed(1))

    this.dayWarningsLimitValue = limit

    this.widgetTarget.classList.remove('d-none')
  }

  activeTabValueChanged() {
    if(this.activeTabHasWarnings() && this.subjectSelectedValue) {
      this.widgetTarget.classList.remove('d-none')
    } else {
      this.widgetTarget.classList.add('d-none')
    }
  }

  updateSliderTrackBackground() {
    this.inputTarget.style.setProperty('--track-percent', `${this.proportionPercentValue}%`)
  }

  activeTabHasWarnings() {
    return(this.activeTabValue != 'user' && this.activeTabValue != 'approvee')
  }
}
