import { sliceEvents, createPlugin } from '@fullcalendar/core'

const CustomListViewConfig = {
  classNames: [ 'custom-view', 'fc-list-view'],

  content: (props) => {
    const segs = sliceEvents(props, true) // allDay=true

    if (segs.length === 0) {
      const notice = document.getElementById('eleave-calendar-custom-list-no-events-notice-template').innerHTML

      return { html: notice }
    }

    const thead = document.getElementById('eleave-calendar-custom-list-thead-template').innerHTML
    const tr = document.getElementById('eleave-calendar-custom-list-tr-template').innerHTML

    const html =
      `<table class="fc-list-table">
          ${thead}
          <tbody data-controller='eleave--custom-list-view clickable'>
          ${segs.map((seg) => {
            // console.log('seg', seg)

            return tr
              .replace(/USER/g, seg.def.extendedProps.owner)
              .replace(/LEAVE_ALLOWANCE_NAME/g, seg.def.extendedProps.leave_allowance_name)

              .replace(/ROW_CLASSES/g, trClasses(seg).join(' '))
              .replace(/TITLE/g, seg.title)

              //.replace(/FROM_DATE/g, seg.range.start.toUTCString())
              .replace(/FROM_DATE/g, seg.def.extendedProps.from_date)
              .replace(/START_TIME/g, seg.def.extendedProps.start_time)

              //.replace(/UNTIL_DATE/g, seg.range.end.toUTCString())
              .replace(/UNTIL_DATE/g, seg.def.extendedProps.until_date)
              .replace(/END_TIME/g, seg.def.extendedProps.end_time)

              .replace(/SUMMARY/g, seg.def.extendedProps.summary)
              .replace(/DATA_ID/g, seg.def.publicId)
              .replace(/DATA_URL/g, seg.def.url)

          }).join('')}
        </tbody>
      </table>`

    return { html: html }
  },

  didMount: (_props) => {
    const noEventsEl = document.getElementById('eleave-calendar-custom-list-no-events-notice')
    if(noEventsEl) {
      noEventsEl.remove()
    }
  },
}

const trClasses = (seg) => {
  return [
    'fc-list-item',
    seg.def.extendedProps.state.toLowerCase().replace(/ /g, '-'),
    seg.def.extendedProps.type.toLowerCase(),
    seg.def.url ? 'fc-has-url' : null
  ].filter(Boolean)
}

export default createPlugin({
  views: {
    custom: CustomListViewConfig
  }
})
