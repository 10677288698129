// clickable elements like row

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  open(event) {
    const url = event.params.url
    const modal = event.params.modal == true
    const accept = event.params.accept || 'text/vnd.turbo-stream.html'

    if(modal) {
      this.renderTurboStreamFromUrl(url, accept)
    } else {
      document.location = url
    }
  }

  renderTurboStreamFromUrl(url, accept) {
    fetch(url, {
      headers: {
        Accept: accept
      }
    })
    .then(r => r.text())
    .then(html => {
      html = `<turbo-stream action="replace" target="modal"><template>${html}</template></turbo-stream>`
      //console.log(html);
      Turbo.renderStreamMessage(html)
    })
  }
}
