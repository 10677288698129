import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // TODO: temporary controller until turbo rails updated to at least 7.3.0, but better 8.x
    // allows GET links with turbo stream response, used e.g. for Add Signatory button
    addEventListener('turbo:before-fetch-request', (event) => {
      const { headers } = event.detail.fetchOptions || {}
      if (headers) {
        headers.Accept = ['text/vnd.turbo-stream.html', headers.Accept].join(', ')
      }
    })
  }

  removeNewSignatory(event) {
    event.preventDefault()

    const container = document.getElementById(event.params.containerCssId)
    container.remove()
  }
}
