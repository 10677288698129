import moment from 'moment'

export const Eleave = {
  LeavePeriodAllowanceEstimator: class {
    constructor(leave_period, public_holiday_dates) {
      this.args = leave_period
      this.publicHolidayDates = public_holiday_dates
    }

    call() {
      return this._days(this.args);
    }

    _days = (args) => {
      if (this._isImpossibleTimespan(args.from_date, args.until_date)) {
        return args.units === 'hours' ? 8.0 : 1.0;
      } else if (this._isSingleDay(args)) {
        return this._proportionOfSingleDay(args);
      } else {
        return this._proportionOfFrom(args) + this._daysBetween(args) + this._proportionOfUntil(args);
      }
    }

    _countFrom = (args) => {
      return args.count_all_days || this._isWorkingDay(moment(args.from_date, 'DD/MM/YYYY'));
    }

    _countUntil = (args) => {
      return args.count_all_days || this._isWorkingDay(moment(args.until_date, 'DD/MM/YYYY'));
    }

    _daysBetween = (args) => {
      let days = 0;
      let date = moment(args.from_date, 'DD/MM/YYYY').add(1, 'days');
      const untilDate = moment(args.until_date, 'DD/MM/YYYY').subtract(1, 'days');

      while (untilDate.diff(date, 'days') >= 0) {
        if (args.units === 'hours') {
          if (args.count_all_days || this._isWorkingDay(date)) {
            days += 8;
          }
        } else {
          if (args.count_all_days || this._isWorkingDay(date)) {
            days += 1;
          }
        }
        date = date.add(1, 'days');
      }
      return days;
    }

    _isImpossibleTimespan = (from_date, until_date) => {
      return moment(from_date, 'DD/MM/YYYY').diff(moment(until_date, 'DD/MM/YYYY')) > 0;
    }

    _isSingleDay = (args) => {
      return args.from_date === args.until_date;
    }

    _proportionOfSingleDay = (args) => {
      return this._countFrom(args) ? this._proportionOfDay(args.end_time, args.start_time, args.units) : 0;
    }

    _proportionOfFrom = (args) => {
      return this._countFrom(args) ? this._proportionOfDay('17:00', args.start_time, args.units) : 0;
    }

    _proportionOfUntil = (args) => {
      return this._countUntil(args) ? this._proportionOfDay(args.end_time, '09:00', args.units) : 0;
    }

    _minutesFromMidnight = (time_string) => {
      const times = time_string.split(':');
      let minutes = parseInt(times[0], 10) * 60;
      minutes += parseInt(times[1], 10);
      return minutes;
    }

    _proportionOfDay = (from, to, units) => {
      const minutes = this._minutesFromMidnight(from) - this._minutesFromMidnight(to);
      return units === 'hours' ? this._proportion_hours(minutes) : this._proportion_days(minutes);
    }

    _proportion_days = (minutes) => {
      if (minutes > 240) {
        return 1;
      } else if (minutes > 0) {
        return 0.5;
      } else {
        return 0;
      }
    }

    _proportion_hours = (minutes) => {
      return minutes > 0 ? minutes / 60 : 0;
    }

    _isWorkingDay = (date) => {
      const dayOfWeek = date.isoWeekday();
      return dayOfWeek !== 6 && dayOfWeek !== 7 && !this._isPublicHoliday(date);
    }

    _isPublicHoliday = (date) => {
      return _.some(this.publicHolidayDates, d => d == date.format('YYYY-MM-DD'))
    }
  }
}

