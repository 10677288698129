import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  disconnect() {
    this.toggleMonthNav()
  }

  toggleMonthNav() {
    const year = document.querySelector('.fc-customYear-view')

    document.querySelectorAll('.fc-header-toolbar .fc-prev-button, .fc-header-toolbar .fc-next-button').forEach(element =>
      year ? element.classList.add('d-none') : element.classList.remove('d-none')
    )
  }
}
