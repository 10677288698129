// features on form submit

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { closeModal: { type: Boolean, default: false }, dispatchEvent: { type: String } }
  static targets = [ 'takenOrAccruedLabel' ]

  submitEnd(event) {
    if(event.detail.success) {
      if(this.closeModalValue) this.dispatch('modal-close')
      if(this.dispatchEventValue) this.dispatch(this.dispatchEventValue)
    }
  }
}
