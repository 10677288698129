import { Controller } from '@hotwired/stimulus'

import { Calendar } from '@fullcalendar/core'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default class extends Controller {
  static values = { url: String, jobPlanStartDate: String, jobPlanEndDate: String }

  connect() {
    this.calendar = this.prepareCalendar()
    this.calendar.render()
  }

  prepareCalendar() {
    const that = this

    const events = {
      url: this.urlValue,
      method: 'GET',
      failure: (err) => {
        console.log('there was an error while fetching events!', err)
      },
      success: (data) => {
        // console.log('data', data)
      }
    }

    const calendar = new Calendar(this.element, {
      timeZone: 'UTC',
      initialDate: this.jobPlanStartDateValue,
      plugins: [ bootstrap5Plugin, dayGridPlugin, interactionPlugin ],
      themeSystem: 'bootstrap5',
      events: events,
      firstDay: 1,
      height: 'auto',
      contentHeight: 'auto',
      headerToolbar: {
        left: '',
        center: 'title',
        right: 'prev,next'
      },
      buttonIcons: {
        prev: 'left-single-arrow',
        next: 'right-single-arrow'
      },
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      eventTextColor: 'black',
      validRange: {
        start: that.jobPlanStartDateValue,
        end: that.jobPlanEndDateValue
      },
      selectAllow: false,
      selectable: false,
      // not needed atm for non medical one
      // eventClick: (info) => {
      //   console.log('eventClick', info)
      //   const job_plan_id = info.event.extendedProps.job_plan_id
      //   const job_content_id = info.event.extendedProps.job_content_id
      //   $('#activity-modal .modal-content').load(`/non_medical/online_job_plans/${job_plan_id}/activities/${job_content_id}/edit`)
      //   $('#activity-modal').modal('show')
      // }
    })

    return calendar
  }
}
